import { Controller } from "@hotwired/stimulus";

// controller: mosaic-bs--disable-link-with
export default class extends Controller {
  static values = {
    loadingText: {type: String, default: "..."}
  };

  connect() {
    this.originalText = this.linkElement.innerHTML;
    this.linkElement.addEventListener("click", this.handleClick.bind(this));
    document.addEventListener("turbo:before-fetch-response", this.enable.bind(this));
  }

  handleClick(event) {
    this.disable();
    const href = this.linkElement.getAttribute("href");
    if (!href || href === "#") {
      event.preventDefault();
    }
  }

  disable() {
    this.linkElement.setAttribute("disabled", true);
    this.linkElement.classList.add("disabled");
    this.linkElement.innerHTML = this.loadingTextValue;
  }

  enable() {
    this.linkElement.removeAttribute("disabled");
    this.linkElement.classList.remove("disabled");
    this.linkElement.innerHTML = this.originalText;
  }

  get linkElement() {
    return this.element;
  }
}
