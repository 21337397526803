import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab"];

  switchTarget(event) {
    event.preventDefault();

    const clickedTab = event.currentTarget;
    const targetPaneId = clickedTab.getAttribute("href");
    if (!targetPaneId) return;

    const targetPane = document.querySelector(targetPaneId);
    if (!targetPane) return;

    this.deactivateAll();
    this.activateTab(clickedTab);
    this.activatePane(targetPane);
  }

  switchUrl(event) {
    event.preventDefault();

    const clickedTab = event.currentTarget;
    const url = clickedTab.href;
    if (!url) return;

    this.deactivateAll();
    this.activateTab(clickedTab);

    Turbo.visit(url, {action: "replace", preserveScroll: true});
  }

  deactivateAll() {
    this.tabTargets.forEach(tab => tab.classList.remove("active"));

    const allPanes = document.querySelectorAll(".tab-pane");
    allPanes.forEach(pane => pane.classList.remove("active", "show"));
  }

  activateTab(tab) {
    if (!tab || tab.classList.contains("active")) return;
    tab.classList.add("active");
  }

  activatePane(pane) {
    if (!pane || pane.classList.contains("active")) return;
    pane.classList.add("active", "show");
  }
}
